import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { relayStylePagination } from '@apollo/client/utilities';

const getClient = () => {
    const httpLink = createHttpLink({
        uri: '/graphql/',
    });

    const logoutLink = onError(({ graphQLErrors }) => {
        const unauthenticated = graphQLErrors
            ? graphQLErrors.some(
                  (error) => error.message === 'User is not authenticated.',
              )
            : false;

        if (unauthenticated) {
            console.warn('User is not authenticated.');
        }
    });

    const httpLinkWithLogout = logoutLink.concat(httpLink);

    return new ApolloClient({
        defaultOptions: {
            watchQuery: { fetchPolicy: 'cache-and-network' },
        },
        link: httpLinkWithLogout,
        cache: new InMemoryCache({
            typePolicies: {
                Query: {
                    fields: {
                        users: relayStylePagination([
                            'search',
                            'isActive',
                            'isStaff',
                            'isSuperuser',
                        ]),
                        managedUsers: relayStylePagination([
                            'search',
                            'applicationGroupId',
                            'isActive',
                        ]),
                        groupMembers: relayStylePagination(['id']),
                        groups: relayStylePagination(['search']),
                        managedGroups: relayStylePagination([
                            'search',
                            'applicationGroupId',
                        ]),
                    },
                },
            },
        }),
    });
};

export default getClient;
